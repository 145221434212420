import * as React from "react"
import { Helmet } from 'react-helmet'
import { PageProps } from "gatsby"
import Menu from "../components/Menu";
import { FaInstagram } from "react-icons/fa";

import p1 from "../images/about/p1.jpg"
import p2 from "../images/about/p2.jpg"
import p3 from "../images/about/p3-thumb.jpg"
import p3mov from "../images/about/p3.mp4"
import about from "../images/about/main.jpg"
import p5 from "../images/about/p5.jpg"
import p6 from "../images/about/p6.jpg"
import p7 from "../images/about/p7.jpg"
import p8 from "../images/about/p8.jpg"
import p9 from "../images/about/p9.jpg"
import laura from "../images/about/laura.jpg"
import baukje from "../images/about/baukje.jpg"
import p11 from "../images/about/p11.jpg"
import p12 from "../images/about/p12.jpg"
import p13 from "../images/about/p13.jpg"
import p14 from "../images/about/p14.jpg"
import p15 from "../images/about/p15.jpg"
import p16 from "../images/about/p16.jpg"
import p17 from "../images/about/p17-thumb.jpg"
import p17mov from "../images/about/p17.mp4"

import GalleryImage from "../components/GalleryImage";
import Footer from "../components/Footer";
import Card from "../components/Card";

const Index: React.FC<PageProps> = () => {
  return (
    <div className="min-h-screen">
      {/* @ts-ignore */}
      <Helmet title="Elbae">
        <script src="https://rawcdn.githack.com/nextapps-de/spotlight/0.7.8/dist/spotlight.bundle.js"></script>
      </Helmet>
      
      <Menu />
      <main className="flex flex-wrap flex-row mt-24">

        <Card centered>
            <h1 className="font-superclarendon text-4xl mb-6">About Elbae</h1>
            <p>
                A fashion label that combines the love for haute couture with streetwear to create new and experimental silhouettes. Elegantly urban best describes the vision for our label. For everyone who feels called to wear our designs. 
            </p>
        </Card>
        <Card backgroundImage={about} backgroundPosition="center center" />

        <h1 className="w-full text-center font-superclarendon text-4xl mt-12">Meet the team</h1>

        <Card centered height=" " backgroundPosition="center center">
            <img src={laura} className="w-72 block rounded-lg mb-12" />
            <h1 className="font-superclarendon text-4xl mb-6">Laura Snijder</h1>
            <p>
            “I always design with the concept of ‘dare to be yourself’ in the back of my mind. You’re allowed to be bold, to live and to be yourself because you are worth it. 
In my designs I really like to work with soft, elegant and feminine silhouettes. I am in love with old handcrafts like crocheting and weaving. It’s fantastic to see how elegant lines combine with cool streetwear. And in my opinion this contrast between styles works fabulously.” 
            </p>
            <p className="mt-4"><a href="https://www.instagram.com/laurasnijder_/" target="_blank"><FaInstagram size={24} className="inline-block mr-4 hover:text-white" /></a></p>
        </Card>
        {/* <Card centered height=" ">
            <img src={baukje} className="w-72 block rounded-lg mb-12" />
            <h1 className="font-superclarendon text-4xl mb-6">Baukje Janssen</h1>
            <p>
              “I am very excited to see my love for streetwear, oversized silhouettes and denim combined with elegant silhouettes and details. A purpose I always have for my designs is that they are for everyone and anyone who feels comfortable wearing them. With oversized silhouettes I try to create a safe space for anyone who is not ready to face all the judgment this world has or for who wants to cover up. At the same time I want to make clothing that gives you all the freedom to show yourself. Everything is made with love for people.” 
            </p>
            <p className="mt-4"><a href="https://www.instagram.com/baukje_xx/" target="_blank"><FaInstagram size={24} className="inline-block mr-4 hover:text-white" /></a></p>
        </Card> */}

        <section className="overflow-hidden bg-secondary">
            <div className="w-full px-5 py-2 mx-auto lg:py-12 lg:px-32">
            <div className="flex flex-wrap -m-1 md:-m-2">
                <GalleryImage src={p1} />
                <GalleryImage src={p2} />
                <div className="flex flex-wrap w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5">
                  <div className="w-full p-1 md:p-2">
                    <a className="spotlight" data-media="video" data-src-mp4={p3mov} data-poster={p3} data-autoplay="true"><img alt="gallery" className="block object-cover object-center w-full h-full rounded-lg"
                      src={p3} /></a>
                  </div>
                </div>
                <GalleryImage src={p5} />
                <GalleryImage src={p6} />
                <GalleryImage src={p7} />
                <GalleryImage src={p8} />
                <GalleryImage src={p9} />
                <GalleryImage src={p11} />
                <GalleryImage src={p12} />
                <GalleryImage src={p13} />
                <GalleryImage src={p14} />
                <GalleryImage src={p15} />
                <GalleryImage src={p16} />
                <div className="flex flex-wrap w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5">
                  <div className="w-full p-1 md:p-2">
                    <a className="spotlight" data-media="video" data-src-mp4={p17mov} data-poster={p17} data-autoplay="true"><img alt="gallery" className="block object-cover object-center w-full h-full rounded-lg"
                      src={p17} /></a>
                  </div>
                </div>
            </div>
            </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}

export default Index
